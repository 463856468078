import React, { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  Modal,
  Tooltip,
  TextField,
  Button
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import { loginSchema } from "../../schema/authSchema";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { login } from "../../redux/apiCall";
import { useDispatch } from "react-redux";
import graphqlRequest from "../../requestMethod";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneEmailOtp from "../../components/PopUp/Otp/PhoneEmailOtp/PhoneEmailOtp";
import ForgetPassword from "../../components/PopUp/Otp/ForgetPassword/ForgetPassword";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Google from "../../services/Google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useRef } from "react";
import MobileViewPopUp from "../../components/PopUp/MobileViewPopUp/MobileViewPopUp";
import { signUpSuccess } from "../../redux/userReducer";
import eventHelpers from "../../Helpers/Event";
import "./LoginForm.css";

const LoginForm = () => {
  //   const [email, setEmail] = useState("");
  //   const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginMethod, setLoginMethod] = useState("password");

  const [Tabvalue, setTabValue] = React.useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFatching } = useSelector((state) => state);
  const [isOtpModelOpen, setOtpModelOpen] = useState(false);
  const [userInput, setUserInput] = useState(null);
  const [token, setToken] = useState("");
  const [forgetModel, setForgetModel] = useState(false);
  const [forgetEmailOrPassword, setForgetEmailOrPassword] = useState("");
  const [resetToken, setResetToken] = useState("");
  const [forgetPasswordToken, setForgetPasswordToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [successForgetToken, setSuccessForgetToken] = useState("");
  //   const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showMobilePopUp, setShowMobilePopUp] = useState(false);

  let [emailError, setEmailError] = useState({
    test: "",
  });
  let [logincred, setLoginCred] = useState({
    email: "",
    password: "",
  });
  let [otpCred, setOtpCred] = useState({
    emailPhone: "",
  });
  let [otpCredError, setOTPCredError] = useState({
    emailPhone: "",
  });
  let [loginCredError, setLoginCredError] = useState({
    test: "",
  });
  let [passwordChange, setPasswordChnage] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  let [passwordChangeError, setPasswordChangeError] = useState({
    test: "",
  });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const {
    getValues,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  const handleLoginSubmit = async (data, e) => {
    e.preventDefault();
    const response = await login(dispatch, data);
    console.log("login Response-->", response.data);
    if (response?.data?.loginV1) {
      if (response?.data?.loginV1?.isAdmin === true) {
        // console.log("Storing token in localStorage:", response.data.login.accessToken);
        navigate("/admin/login");
        localStorage.setItem("adminToken", response.data.loginV1.accessToken);
      } else if (
        response?.data?.loginV1?.verifyToken &&
        response.data?.loginV1?.accessToken == null
      ) {
        navigate("/login/setpassword");
        localStorage.setItem(
          "SetVerifyToken",
          response.data.loginV1.verifyToken
        );
      } else {
        navigate("/");
        message.success("Login Successful!");
      }
    }
    //  else if (screenSize.width < 767 && response?.data?.loginV1) {
    //   setShowMobilePopUp(true);
    //   openBlitzllamaSurvey();
    // }
    else {
      message.error("Email or Password is incorrect");
    }
  };

  const inputRef = useRef(null);

  function loginWithOtp() {
    const query = `
    query {
      loginWithOtp(emailOrPhone:"${userInput}",isWeb:true)
    } 
  `;

    graphqlRequest(query).then((response) => {
      if (response?.errors?.[0]?.message === "Check your credentials!") {
        message.error("Email/Phone number is not registered with us!");
      } else {
        console.log(response);
        setToken(response?.data?.loginWithOtp);
        setOtpModelOpen(true);
        message.success("OTP has been sent.");
      }
    });
  }
  function loginWithForgetOtp() {
    const query = `
    query {
      forgotPasswordHospital(email:"${forgetEmailOrPassword}")
    } 
  `;
    graphqlRequest(query).then((response) => {
      if (response?.data?.forgotPasswordHospital) {
        setForgetPasswordToken(response?.data?.forgotPasswordHospital);
        message.success("OTP Sent Successfully ");
        setForgetModel(true);
      } else {
        message.error("Email is not registered with us!");
        // console.log("Forgetresponse",response)
        // alert("Check your credentials!")
        // setToken(response.data.loginWithOtp)
      }
    });
  }
  function handleResendForgetOtp() {
    const query = `
    query {
      forgotPasswordHospital(email:"${forgetEmailOrPassword}")
    } `;
    graphqlRequest(query).then((response) => {
      setForgetPasswordToken(response?.data?.forgotPasswordHospital);
    });
  }
  function handleResendOtp() {
    const query = `
    query {
      loginWithOtp(emailOrPhone:"${userInput}",isWeb:true,resend:true)
    }
  `;

    graphqlRequest(query).then((response) => {
      setToken(response.data.loginWithOtp);
    });
  }
  let handleOtpVerification = () => {
    loginWithOtp();
  };
  let handleForgetModel = () => {
    setForgetModel(!forgetModel);
  };
  function validatemyEmail() {
    let error = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!forgetEmailOrPassword) {
      error.email = "please provide you mail id ";
    }
    if (!regex.test(forgetEmailOrPassword)) {
      error.email = "Invalid Mail Id ";
    }
    return error;
  }
  useEffect(() => {
    setEmailError(validatemyEmail);
  }, [forgetEmailOrPassword]);

  let handleForgetPassword = () => {
    loginWithForgetOtp();
  };
  const handleNewPAssword = () => {
    const query = `mutation {
      resetPassword(
          resetToken: "${successForgetToken}",
          password: "${passwordChange?.newPassword}"
      )
    }`;
    graphqlRequest(query).then((response) => {
      if (response) {
        message.success("Password Reset successfully");
        setTabValue(0);
      } else {
        message.error("Someting Went Wrong");
      }
    });
  };

  function validateLoginError() {
    let error = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!logincred.email) {
      error.email = "Please provide you email";
    }
    if (!regex.test(logincred.email)) {
      error.email = "Please enter valid Email";
    }
    if (!logincred.password) {
      error.password = "Please provide your password";
    }
    if (!logincred.password) {
      error.password = "Please provide your password";
    }
    if (logincred?.password?.length == 0) {
      error.password = "Password Field cannot be blank";
    }
    if (
      logincred?.password?.length > 0 &&
      (logincred?.password?.length < 8 || logincred?.password?.length > 16)
    ) {
      error.password = "Please enter valid Password";
    }
    return error;
  }
  function validateChangePassword() {
    const regex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()_+={}|[\]\\:;\"\'<>,./?-]).{8,16}$/;
    let error = {};
    if (passwordChange.newPassword !== passwordChange.confirmPassword) {
      error.password = "Password Missmatch";
    }
    if (!regex.test(passwordChange.newPassword)) {
      error.password = "Invalid Password";
    }

    return error;
  }
  function validateOTPError() {
    let error = {};

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^(\+91-|\+91|0)?\d{10}$/;
    // let isValidEmail = emailRegex.test(value);
    // let isValidPhone = phoneRegex.test(value);

    // if (!isValidEmail && !isValidPhone) {
    //   return false;
    // }
    // if (isValidEmail) {
    //   return "emailAddress";
    // }
    // if (isValidPhone) return "number";

    let isValidEmail = emailRegex.test(userInput);
    let isValidPhone = phoneRegex.test(userInput);
    if (!userInput) {
      console.log("11111");
      error.emailPhone = "Email or Mobile Number Field cannot be Blank";
    } else if (!isValidEmail && !isValidPhone) {
      error.emailPhone = "Please enter Valid Email or Mobile Number";
    }
    // if (isValidEmail) {
    //   return "emailAddress";
    // }
    // if (isValidPhone) return "number";
    // if (!emailRegex.test(userInput) || !phoneRegex.test(userInput)) {
    //   console.log("22222");
    //   error.emailPhone = "invalid email/phone";
    // }
    // if (!phoneRegex.test(userInput)) {
    //   console.log("33333");
    //   error.emailPhone = "invalid phone";
    // }
    return error;
  }
  useEffect(() => {
    setLoginCredError(validateLoginError());
  }, [logincred]);
  useEffect(() => {
    setOTPCredError(validateOTPError());
  }, [userInput]);
  useEffect(() => {
    setPasswordChangeError(validateChangePassword());
  }, [passwordChange]);

  useEffect(() => {
    setTimeout(() => {
      // document.getElementById("header").click();
      inputRef.current.focus();
      // console.log("clicked", document.getElementById("header"));
    }, "3000");
  }, []);

  useEffect(() => {
    dispatch(signUpSuccess());
  }, [window.location.pathname]);

  const pressEnter = (e) => {
    if (e.keyCode == 13) {
      handleLoginSubmit(logincred, e);
    }
  };
  const pressEnterOTP = (e) => {
    if (e.keyCode == 13) {
      handleOtpVerification(userInput, e);
    }
  };

  //   const pressEnter = (e) => {
  //     if (e.key === 'Enter') {
  //         handleLoginSubmit(logincred, e);
  //     }
  //   };

  // console.log(email)
  // console.log(password)
  // const isFormValid = email && password;
  console.log("loginMethod>>",loginMethod)

  return (
    <>
      <div className="login-form-container">
        <h2>Get Started Now</h2>
        <div className="login-options">
          <button
            className={`login-option-btn ${
              loginMethod === "password" ? "active" : ""
            }`}
            onClick={() => setLoginMethod("password")}
          >
            LOGIN WITH PASSWORD
          </button>
          <button
            className={`login-option-btn ${
              loginMethod === "otp" ? "active" : ""
            }`}
            onClick={() => setLoginMethod("otp")}
          >
            LOGIN WITH OTP
          </button>
          {/* <button
            className={`login-option-btn ${
              loginMethod === "forgot" ? "active" : ""
            }`}
            onClick={() => setLoginMethod("forgot")}
          >
            Forgot Password?
          </button> */}
        </div>
        <form onSubmit={handleSubmit}>
          {loginMethod === "password" ? (
            <>
              <div className="form-group">
                <input
                  placeholder="Enter your Email address"
                  type="email"
                  id="email"
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={(e) =>
                    setLoginCred({
                      ...logincred,
                      email: e.target.value,
                    })
                  }
                  onBlur={() => {
                    if (loginCredError.email) {
                      message.error(loginCredError.email);
                    }
                  }}
                  required
                />
              </div>
              <div className="form-group password-group">
                <input
                  placeholder="Enter Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  // value={password}
                  // onChange={(e) => setPassword(e.target.value)}
                  onChange={(e) =>
                    setLoginCred({
                      ...logincred,
                      password: e.target.value,
                    })
                  }
                  onBlur={() => {
                    if (loginCredError.password) {
                      message.error(loginCredError.password);
                    }
                  }}
                  onKeyDown={pressEnter}
                  required
                />
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                <span
                  onClick={(e) => {
                    handleChange(e, 2);
                  }}
                ></span>
              </div>
            </>
          ) : loginMethod === 'otp' ? (
            <div className="form-group">
              <input
                name="phone"
                id="emailPhone"
                placeholder="Email / Phone"
                type="text"
                //   value={email}
                //   onChange={(e) => setEmail(e.target.value)}
                required
                onBlur={() => {
                  if (otpCredError.emailPhone) {
                    message.error(otpCredError.emailPhone);
                  }
                }}
                onChange={(e) => {
                    e.preventDefault();
                    setUserInput(e.target.value)}}
                onKeyDown={pressEnterOTP}
              />
            </div>
          ) :loginMethod === 'setnewpassword' ? 
          (
            <div className="set-password-container">
            <Tooltip
              placement="right"
              title="Your password must be 8-16 characters long and include at least one uppercase letter, one lowercase letter, one special character, and one number."
            >
              <InfoIcon />
            </Tooltip>
            <div className="input-otp-fields">
              <span>Create New Password</span>
              <TextField
                label="Enter New Password"
                type={showPassword ? "text" : "password"}
                sx={{
                  border: 'none',
                  outline: 'none',
                  '& input': {
                    border: 'none', // Remove border from the input field
                    outline: 'none' // Remove outline from the input field
                  }
                }}
                autoComplete="off"
                onBlur={() => {
                  if (passwordChangeError.password) {
                    message.error(passwordChangeError.password);
                  }
                }}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setPasswordChnage({
                    ...passwordChange,
                    newPassword: e.target.value,
                  })
                }
              />
            </div>
            <div className="input-otp-fields">
              <TextField
                label="Re-Enter New Password"
                type={showPassword2 ? "text" : "password"}
                fullWidth
                autoComplete="off"
                onBlur={() => {
                  if (passwordChangeError.password) {
                    message.error(passwordChangeError.password);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword2}>
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) =>
                  setPasswordChnage({
                    ...passwordChange,
                    confirmPassword: e.target.value,
                  })
                }
              />
            </div>
            <div className="input-otp-fields">
              <Button
                variant="contained"
                fullWidth
                onClick={handleNewPAssword}
                disabled={Object.keys(passwordChangeError).length !== 0}
              >
                Submit
              </Button>
            </div>
          </div>
          )
          
          :(
            <div>
            <div className="form-group">
              <span>Please enter your registered email ID to reset Password.</span>
              <input
                label="Verify Email"
                fullWidth
                autoComplete="off"
                id="emailPhone"
                placeholder="Verify Email"
                type="text"
                //   value={email}
                //   onChange={(e) => setEmail(e.target.value)}
                required
                onBlur={() => {
                    if (emailError.email) {
                      message.error(emailError.email);
                    }
                  }}
                  onChange={(e) =>
                    setForgetEmailOrPassword(e.target.value)
                  }
               
              />
              <div
                className="input-otp-fields"
                style={{ display: 'flex', gap: '20px', marginTop: '20px' }}
              >
                <Button
                  variant="contained"
                  style={{marginLeft: "20px", width: '40%' }}
                  onClick={() => setLoginMethod('password')}
                >
                  Cancel
                </Button>
              
                <Button
                  variant="contained"
                  style={{ width: '40%' }}
                  onClick={handleForgetPassword}
                  disabled={Object.keys(emailError).length !== 0}
                  // className={`login-forgot-btn ${
                  //   loginMethod === "setpassword" ? "active" : ""
                  // }`}
                  // onClick={(e) => {
                  //   handleForgetPassword()
                  //     e.preventDefault();
                  //     setLoginMethod("setpassword");
                  //   }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
          )
          
          }

          <div className="form-group inline">
            {/* <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label htmlFor="rememberMe">
              I agree to these{" "}
              <a href="/terms" style={{ color: "blue" }}>
                Terms and Conditions
              </a>
            </label> */}
            <button
            className={`login-forgot-btn ${
              loginMethod === "forgot" ? "active" : ""
            }`}
            onClick={(e) => {
                e.preventDefault(); // Prevent default form submission behavior
                setLoginMethod("forgot");
              }}
          >
            Forgot Password?
          </button>
          
            
          </div>
          {/* <div> */}
          
          {/* </div> */}
          
          {loginMethod === "password" ? (
            <div className="input-fields">
              <button
                type="submit"
                className="login-form-btn"
                disabled={Object.keys(loginCredError).length !== 0}
                onClick={(e) => handleLoginSubmit(logincred, e)}
              >
                {isFatching ? <CircularProgress /> : "Log In"}
              </button>

              <div className="login-buttons">
                <GoogleOAuthProvider clientId="1010923642758-7j3ub289s55cphbel85vtnkov74vhjjq.apps.googleusercontent.com">
                  <Google dispatch={dispatch} navigate={navigate} />
                </GoogleOAuthProvider>
              </div>
            </div>
          ) : (
            <div className="input-fields">
              <button
                type="submit"
                className="login-form-btn"
                disabled={Object.keys(otpCredError).length !== 0}
                onClick={(e) => {
                    e.preventDefault();
                  handleOtpVerification(userInput, e);
                }}
              >
                {isFatching ? <CircularProgress /> : "Log In"}
              </button>

              <div className="login-buttons">
                <GoogleOAuthProvider clientId="1010923642758-7j3ub289s55cphbel85vtnkov74vhjjq.apps.googleusercontent.com">
                  <Google dispatch={dispatch} navigate={navigate} />
                </GoogleOAuthProvider>
              </div>
            </div>
          )}
        </form>
      </div>
      <Modal
        open={isOtpModelOpen}
        onClose={setOtpModelOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PhoneEmailOtp
          openOtpHandler={isOtpModelOpen}
          setOpenOtpHandler={setOtpModelOpen}
          verifyMode="Email id"
          token={token}
          handleResendOtp={handleResendOtp}
          userInput={userInput}
        />
      </Modal>
      <Modal
        open={forgetModel}
        onClose={handleForgetModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ForgetPassword
          setSuccessForgetToken={setSuccessForgetToken}
          forgetPasswordToken={forgetPasswordToken}
          setTabValue={setTabValue}
          setLoginMethod = {setLoginMethod}
          loginMethod = {loginMethod}
          forgetModel={forgetModel}
          setForgetModel={setForgetModel}
          handleResendForgetOtp={handleResendForgetOtp}
          forgetEmailOrPassword={forgetEmailOrPassword}
        />
      </Modal>
      <Modal
        open={showMobilePopUp}
        onClose={setShowMobilePopUp}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MobileViewPopUp
          openOtpHandler={showMobilePopUp}
          setOpenOtpHandler={setShowMobilePopUp}
        />
      </Modal>
    </>
  );
};

export default LoginForm;
