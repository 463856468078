import React, { useEffect, useState } from "react";
import "./ProfileDetails.scss";
import close from "../../asset/recommendation/close.svg";
import star from "../../asset/recommendation/star.svg";
import download from "../../asset/recommendation/download.svg";
import file from "../../asset/recommendation/file.svg";
import { removeProfile } from "../../redux/userReducer";
import { useDispatch, useSelector } from "react-redux";
import graphqlRequest from "../../requestMethod";
import { message } from "../Common/SnakeAlert/SnakeAlert";
import Avtar from "../../asset/avtar.png";
import { CircularProgress } from "@mui/material";
import badge from "../../asset/badge.svg";
import { getRandomNumber } from "./../Profile/RandomNumber";


// const getRandomNumber = () => Math.floor(Math.random() * (20 - 5 + 1)) + 5;
function ProfileDetails({ setOpenProfileView, showProfileData, recommendedUserAbsolute }) {
  let dispatch = useDispatch();
  let { userJobPostDetails } = useSelector((state) => state.user);
  let [isResumeLoading, setResumeLoading] = useState(false);
  let data = localStorage.getItem("auth");
  let token = JSON.parse(data)?.accessToken;

  const getFileExtension = (url) => {
    const parts = url.split(".");
    // console.log(">>>>>>>1>>>>>>>",parts)
    // const data1 = parts[parts.length - 1].split("?")[0];
    // console.log(">>>>>>>2>>>>>>>",data1)
    // return data1
    return parts[parts.length - 1].split("?")[0];
  };

  const handleDownLoadResume = () => {
    setResumeLoading(true);
    const query = `{
          withAuth(token:"${token}"){
           getUserPdf(id: "${showProfileData?.id}")
          }
        }`;
     graphqlRequest(query).then((response) => {
      if (response.data.withAuth.getUserPdf) {
        setResumeLoading(false);
        const data = response?.data?.withAuth?.getUserPdf;
        const linkSource = `data:application/pdf;base64,${data}`;
        let a = document.createElement("a");
        a.href = linkSource;
        // a.download = "resume.pdf";
        a.download = `${showProfileData.name.full}_resume.pdf`;
        a.click();
        message.success("Download Completed.");
      }else{
        setResumeLoading(false);
        message.error("Currently we don't have File to Download.");
      }
      // else {
      //   const query = `{
      //     withAuth(token:"${token}"){
      //      getUser(id: "${showProfileData?.id}"){
      //       documents {
      //         docType {
      //           name
      //         }
      //         files {
      //           secureUrl
      //           uri
      //         }
      //       }
      //      }
      //     }
      //   }`;
    
      //   graphqlRequest(query).then((response) => {
      //     // console.log("46-->", response)
      //     if (response?.data?.withAuth?.getUser?.documents?.length >= 0) {
      //       setResumeLoading(false);
      //       const data = response.data.withAuth.getUser.documents.find((doc) => {
      //         return doc.docType?.name === "CV";
      //       });
    
      //       if (!data) {
      //         setResumeLoading(false);
      //         message.error("Professional CV Not Available");
      //       }
    
      //       const linkSource = data.files[0].uri;
      //       fetch(linkSource)
      //         .then((response) => response.blob())
      //         .then((blob) => {
      //           const filename = `${showProfileData.name.full}_resume.${getFileExtension(linkSource)}`;
    
      //           const url = window.URL.createObjectURL(blob);
      //           const a = document.createElement("a");
      //           a.href = url;
      //           a.download = filename;
      //           a.style.display = "none";
      //           document.body.appendChild(a);
      //           a.click();
      //           window.URL.revokeObjectURL(url);
      //         })
      //         .catch((error) => {
      //           console.error("Error downloading the file:", error);
      //         });
      //     } else {
      //       setResumeLoading(false);
      //       message.error("Currently we don't have File to Download.");
      //     }
      //   });
      // }
    });
  };

  let [invitedUser1, setInvitedUser1] = useState({});

  let handlejobInvite = () => {
    let query = `
      query{
        withAuth(token:"${token}"){
          inviteUser(jobId:"${userJobPostDetails?.id}",userId:"${showProfileData?.id}")
        }
      }
      `;
    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        message?.error("Already invited!");
      }
      if (response?.data?.withAuth?.inviteUser) {
        // message?.success("Email has been sent");
        message?.success("Successfully Invited");
        // invitedUser.push({
        //   [showProfileData.id]: true,
        // });

        setInvitedUser1((invitedUser1) => ({
          ...invitedUser1,
          [showProfileData.id]: true,
        }));
      }
    });
  };

  let valueToBeMatched;

  let keyToBeMatched = `${showProfileData.id}` in invitedUser1;

  useEffect(() => {
    for (var i = 0; i <= invitedUser1.length; i++) {
      for (var key in invitedUser1[i]) {
        // console.log(invitedUser1[i][key], "abbe o");
        valueToBeMatched = invitedUser1[i][key];
      }
    }
  }, [invitedUser1]);

  const randomNumber = getRandomNumber();

  // console.log("recommendedUserAbsolute", recommendedUserAbsolute);

  const isAbsoluteProfile = recommendedUserAbsolute?.some(abs => abs.id === showProfileData.id);
  const isSimilarProfile = !isAbsoluteProfile;


  return (
    <div className="profile-details-wrapper">
      <div className="profile-details-container">
        <div className="pd-one">
          <div className="close-profile">
            <img
              src={close}
              onClick={() => {
                setOpenProfileView(false);
                dispatch(removeProfile());
              }}
              alt="close"
            />
          </div>
          <div className="flag-tape">
          {isAbsoluteProfile && "Recommended"}
          {isAbsoluteProfile && isSimilarProfile && " & "}
          {isSimilarProfile && "Similar Profile"}
        </div>
          <div className="user-profile-img">
            {showProfileData?.marksPercentage >= 60 && (
              <div
                className="dp-badge-hover"
                data-message="Jobizo Assessment Qualified"
              >
                <img src={badge} className="dp-badge" />
              </div>
            )}
            <img
              src={showProfileData?.avatar?.url || Avtar}
              className={
                showProfileData?.marksPercentage >= 60 ? "badge-border" : ""
              }
            />
            {/* <img src={showProfileData?.avatar?.url || Avtar} /> */}
          </div>
          <div className="user-profile-invite">
            <div className="up-top">
              <p>{showProfileData?.name?.full}</p>
              <span>{showProfileData?.professionalType?.name}</span>
            </div>
            <div className="up-bottom">
              <div>
              {/* {showProfileData.numberOfRatings >= 5 ? (
                      <>
                      {new Array(showProfileData.averageRating)
                        .fill(1)
                        .map((_, index) => (
                          // <img key={index} src={star} alt="star" />
                          <React.Fragment key={index}>
                            <img src={star} alt="star" />
                            {index === showProfileData.averageRating - 1 && (
                              <span
                                style={{
                                  position: 'relative', 
                                  top: '-3px', 
                                  left: '5px',
                                  fontSize: "0.9em",
                                }}
                              >
                                ({showProfileData.numberOfRatings})
                              </span>
                            )}
                          </React.Fragment>
                        ))}
                      {/* <span>({data.numberOfRatings})</span> */}
                    {/* </>
                    ) : (
                      new Array(showProfileData.rating)
                        .fill(1)
                        .map((_, index) => (
                          <img key={index} src={star} alt="star" />
                        ))
                    )}  */} 

{showProfileData?.numberOfRatings >= 5 ? (
    <>
      {typeof showProfileData.averageRating === 'number' &&
      showProfileData.averageRating >= 0 ? (
        new Array(Math.round(showProfileData.averageRating)).fill(1).map((_, index) => (
          <React.Fragment key={index}>
            <img src={star} alt="star" />
            {index === Math.round(showProfileData.averageRating) - 1 && (
              <span
                style={{
                  position: 'relative',
                  top: '-3px',
                  left: '5px',
                  fontSize: "0.8em",
                }}
              >
                ({showProfileData.numberOfRatings})
              </span>
            )}
          </React.Fragment>
        ))
      ) : (
        <span>Invalid average rating</span>
      )}
    </>
  ) : (
    <>
      {typeof showProfileData?.rating === 'number' &&
      showProfileData?.rating >= 0 &&
      Number.isInteger(showProfileData?.rating) ? (
        // new Array(showProfileData?.rating).fill(1).map((_, index) => (
        //   <img key={index} src={star} alt="star" />
        // ))
        <div style={{ display: "inline-block", position: "relative" }}>
      {new Array(showProfileData?.rating).fill(1).map((_, index) => (
        <img key={index} src={star} alt="star" />
      ))}
      <span
        style={{
          position: 'absolute',
          top: '40%',
          left: 'calc(100% + 2px)',
          transform: 'translateY(-50%)',
          fontSize: "0.8em",
        }}
      >
        ({randomNumber+showProfileData?.rating})
      </span>
    </div> 
      ) : (
        <span>Invalid rating</span>
      )}
    </>
  )}
              </div>
              {keyToBeMatched === true ? (
                <button className="disabled-button">Invited</button>
              ) : (
                <button onClick={handlejobInvite}>Invite To Apply</button>
              )}
            </div>
          </div>
        </div>
        <hr className="hr-line" />
        <div className="pd-two">
          <div className="pd-left">
            <p>Department</p>
            <p>Specialization</p>
            <p>Experience</p>
            <p>Gender</p>
            <p>Location</p>
          </div>
          <div className="pd-right">
            <p>{showProfileData?.departments?.[0]?.name}</p>
            <p>{showProfileData?.specialization?.name}</p>
            <p>{showProfileData?.numExperience?.name}</p>
            <p className="email">{showProfileData?.profile?.gender}</p>
            <p>{showProfileData?.address?.address}</p>
          </div>
        </div>
        <hr className="hr-line" />
        <div className="pd-three">
          <div>
            <div>
              <img src={file} alt="file" />
              <div>
                <p className="file-name">Resume</p>
                <p className="file-size">1.25MB</p>
              </div>
            </div>
            {isResumeLoading ? (
              <CircularProgress style={{ width: "30px", height: "30px" }} />
            ) : (
              <img
                src={download}
                alt="download"
                style={{ cursor: "pointer" }}
                onClick={handleDownLoadResume}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDetails;


// {new Array(showProfileData.rating).fill(0).map(() => {
//   return <img src={star} alt="star" />;
// })}     