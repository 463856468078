import React,{ useEffect, useState } from 'react';
import { Box, TextField, Button, Checkbox, FormControlLabel, Typography, MenuItem, Grid,Select,FormControl,InputLabel,Modal   } from '@mui/material';
import { signUpSuccess } from "../../redux/userReducer";
import graphqlRequest from "../../requestMethod";
import EmailOtp from "../../components/PopUp/Otp/EmailOtp/EmailOtp";
import PhoneOtp from "../../components/PopUp/Otp/PhoneOtp/PhoneOtp";
import { clearState } from "../../redux/apiCall";
import { message } from "../../components/Common/SnakeAlert/SnakeAlert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
    const [emailOtpField, setEmailOtpField] = useState(new Array(4).fill(""));
  const [phoneOtpField, setPhoneOtpField] = useState(new Array(4).fill(""));
  const [modalForEmailOtp, setModalForEmailOtp] = useState(false);
  const [modalForPhoneOtp, setModalForPhoneOtp] = useState(false);
  const [isEmailVerifyed, setIsEmailVerifyed] = useState(false);
  const [isPhoneVerifyed, setIsPhoneVerifyed] = useState(false);
  const [emailToken, setEmailToken] = useState("");
  const [phoneToken, setPhoneToken] = useState("");

  const [isDidsabled, setIsDisabled] = useState(false);

  let [companyTypeList, setCompanyTypeList] = useState([]);
  let [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedCompanyType, setSelectedCompanyType] =
    useState("Type of company*");

  let [term, setTerm] = useState(false);
  let [formError, setFormError] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const UserDetails = useSelector((state) => state?.user?.signUpUserGmail);
  console.log("USER DETAILS", UserDetails);

  let [userRegistration, setUserRegistration] = useState({
    salutation: "",
    organization: "",
    role: "",
    companyType: "",
    website: "",
    name: UserDetails?.name,
    email: UserDetails?.email,
    phone: "",
  });

  function fetchComapnyListData() {
    const query = `

 query{

 listCompanyType{

name,

 id
 }

}

`;

    graphqlRequest(query).then((response) => {
      console.log("Company List", response?.data?.listCompanyType);

      let sortedCompanies = response?.data?.listCompanyType.sort(function (
        a,

        b
      ) {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase

        const nameB = b.name.toUpperCase(); // ignore upper and lowercase

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        } // names must be equal

        return 0;
      });

      console.log("Company List Sorted", sortedCompanies);

      setCompanyTypeList(sortedCompanies);
    });
  }
  function verifyEmail() {
    const query = `
      mutation {
      verifyEmail(email:"${userRegistration?.email}")
      }
    `;
    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        message.error(response.errors[0].message);
      } else {
        setModalForEmailOtp(true);
        setEmailToken(response.data.verifyEmail);
        message.success("OTP has been sent.");
      }
    });
  }
  function verifyPhone() {
    const query = `
    mutation {
      verifyPhone(contact:{code:"91",mobile:"${userRegistration?.phone}"})
    }
  `;
    graphqlRequest(query).then((response) => {
      if (response?.errors) {
        message.error("Phone number already exists!");
      } else {
        setModalForPhoneOtp(true);
        setPhoneToken(response.data.verifyPhone);
        message.success("Otp sent Successfully!");
      }
    });
  }

  useEffect(() => {
    fetchComapnyListData();
    setEmailOtpField();
  }, []);

  const salutationList = [
    {
      id: 1,
      name: "Mr",
    },
    {
      id: 2,
      name: "Mrs",
    },
    {
      id: 3,
      name: "Ms",
    },
    {
      id: 4,
      name: "Dr",
    },
  ];

  function handleUserInput(property, value) {
    setUserRegistration({ ...userRegistration, [property]: value });
    console.log("USER REGISTRATION", userRegistration);
  }
  function validateForm() {
    let error = {};
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!userRegistration.name) {
      error.name = "Name is Required";
    }
    if (
      userRegistration?.name?.length < 3 ||
      userRegistration?.name?.length > 30
    ) {
      error.name =
        "Your name must be greater than 2 char and less than 30 char";
    }
    if (!userRegistration.phone) {
      error.phone = "Phone Number is Required";
    }
    if (userRegistration?.phone?.length !== 10) {
      error.phone = "Phone Number invalid ";
    }
    if (!userRegistration.companyType) {
      error.companyType = "Company Type is required";
    }
    if (!userRegistration.email) {
      error.email = "Email is Required";
    }
    if (!regex.test(userRegistration.email)) {
      error.email = "Please enter valid Email.";
    }
    if (!userRegistration.role) {
      error.role = "Role is required";
    }
    if (
      userRegistration?.role?.length < 2 ||
      userRegistration?.role?.length > 30
    ) {
      error.role = "Role is required";
    }
    if (!userRegistration.salutation) {
      error.salutation = "Salutation is required";
    }
    if (!userRegistration.organization) {
      error.organization = "Registered Company Name is required";
    }
    if (!term) {
      error.term = "Terms and condition needs to be checked";
    }

    if (!isEmailVerifyed) {
      error.emailVerified = "Email Not Verified";
      // message.error("Email Not Verified");
    }

    if (!isPhoneVerifyed) {
      error.isPhoneVerified = "Phone Number not Verified";
      // message.error("Phone Number not Verified");
    }

    return error;
  }

  const handleSubmit = (e) => {
    console.log("inside here")
    navigate('/home');
    e.preventDefault();
    handleRegistrationInput();
  };
    
  function handleRegistrationInput() {
    const query = `
   mutation{
       signupHospital(salutation:${userRegistration?.salutation}, name:"${userRegistration?.name}",organization:"${userRegistration?.organization}",email:"${userRegistration?.email}",contact:{code:"91",mobile:"${userRegistration.phone}"},role:"${userRegistration.role}",companyType:"${userRegistration.companyType}",website:"${userRegistration.website}")
     }
  `;
    graphqlRequest(query).then((response) => {
      console.log("SIGN UP RESPONSE", response);
      // window.dataLayer.push({
      //   event: "sign_up: signup_completed_successfuly",
      // });
      // message.success(
      //   "Thanks for Registering. Email has been sent to you for next steps."
      // );

      console.log(window.dataLayer);
      // console.log("123-->", response);
      const msg = clearState(dispatch);
      if (response?.errors) {
        message.error("Some Issue With SignUp");
      } else {
        // navigate("/login");
        // navigate("/home");
        // trackUserRegistration();

        window.dataLayer.push({
          event: "sign_up: signup_completed_successfuly",
        });
        message.success(
          "Thank you for Registering. Email has been sent to you for next steps."
        );
        dispatch(signUpSuccess());
        navigate("/home");

      }
    });
  }
  useEffect(() => {
    setFormError(validateForm());
  }, [term, userRegistration, isEmailVerifyed, isPhoneVerifyed]);


  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        width: '100%',
        maxWidth: '350px',
        padding: '15px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}
      className="signup-form"
    >
      <Typography variant="h6" gutterBottom sx={{ fontSize: '16px', fontWeight: 'bold' }}>Take your healthcare hiring to the next level</Typography>
      <Grid container spacing={2}> {/* Grid container for form fields */}
        {/* <Grid item xs={12}>
          <TextField required label="Full Name" fullWidth variant="outlined" size="small" />
        </Grid> */}
        <Grid container spacing={1}  alignItems="center" sx={{ marginTop: '.4rem' }}>
          <Grid item xs={.5} />
          <Grid item xs={4}> 
          <FormControl fullWidth required>
      <InputLabel id="salutation-label">Salutation</InputLabel>
      <Select
        labelId="salutation-label"
        id="salutation"
        value={userRegistration.salutation}
        onChange={(e) => handleUserInput('salutation', e.target.value)}
        label="Salutation"
        size="small"
        variant="outlined"
      >
        {salutationList.map((item) => (
          <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
          </Grid>
          <Grid item xs={7.5}>
          {/* <TextField required label="Full Name" fullWidth variant="outlined" size="small" /> */}
          <TextField
                            required
                            name="name"
                            // label="Full Name*"
                            autoComplete="off"
                            label="Full Name"
                            defaultValue={UserDetails?.name}
                            size="small"
                            fullWidth
                            onBlur={() => {
                              if (formError.name) {
                                message.error(formError.name);
                              }
                            }}
                            onChange={(e) =>
                              handleUserInput("name", e.target.value)
                            }
                            variant="outlined"
                            renderValue={(selected) => {
                              if (!selected) return "";
                              else return selected;
                            }}
                          />
          </Grid>
        </Grid>
        {/* Email field and Verify button */}
        {/* <Grid container spacing={1}  alignItems="center" sx={{ marginTop: '.4rem' }}>
          <Grid item xs={.5} /> 
          <Grid item xs={7.5}> 
            <TextField required label="Email" fullWidth variant="outlined" size="small" />
          </Grid>
          <Grid item xs={4}> 
            <Button variant="outlined" color="primary" fullWidth>Verify</Button>
          </Grid>
        </Grid> */}
        <Grid container spacing={1} alignItems="center" sx={{ marginTop: '.4rem' }}>
  <Grid item xs={0.5} /> {/* Empty grid item to shift Email field */}
  <Grid item xs={7.5}> {/* Email field */}
    <TextField
      required
      onChange={(e) => handleUserInput("email", e.target.value)}
      onBlur={() => {
        if (formError.email) {
          message.error(formError.email);
        }
      }}
      name="official_email"
      autoComplete="off"
      defaultValue={UserDetails?.email}
    //   placeholder="Official email id*"
      label="Official email id"
      fullWidth
      variant="outlined"
      size="small"
      disabled={isEmailVerifyed}
    />
  </Grid>
  <Grid item xs={4}> {/* Verify button */}
    {isEmailVerifyed ? (
      <Button
        disabled={formError?.email || isEmailVerifyed}
        fullWidth
        variant="outlined"
        style={{
          backgroundColor: isEmailVerifyed && "green",
          color: isEmailVerifyed && "#fff",
        }}
      >
        Verified
      </Button>
    ) : (
      <Button
        disabled={formError?.email || isEmailVerifyed}
        fullWidth
        variant="outlined"
        color="primary"
        onClick={() => {
          verifyEmail();
        }}
      >
        Verify
      </Button>
    )}
  </Grid>
</Grid>
<Modal
                        open={modalForEmailOtp}
                        onClose={setModalForEmailOtp}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <EmailOtp
                          verifyEmail={verifyEmail}
                          openOtpHandler={modalForEmailOtp}
                          setOpenOtpHandler={setModalForEmailOtp}
                          verifyMode="Email id"
                          emailToken={emailToken}
                          setIsEmailVerifyed={setIsEmailVerifyed}
                          setModalForEmailOtp={setModalForEmailOtp}
                          isEmailVerifyed={isEmailVerifyed}
                          emailLabel={userRegistration?.email}
                          userRegistration={userRegistration}
                        />
                      </Modal>

        {/* Phone field and Verify button */}
        {/* <Grid container spacing={1} alignItems="center" sx={{ marginTop: '.4rem' }}>
          <Grid item xs={.5} /> 
          <Grid item xs={7.5}> 
            <TextField required label="Phone" fullWidth variant="outlined" size="small" />
          </Grid>
          <Grid item xs={4}>
            <Button variant="outlined" color="primary" fullWidth>Verify</Button>
          </Grid>
        </Grid> */}
            <Grid container spacing={1} alignItems="center" sx={{ marginTop: '.4rem' }}>
  <Grid item xs={0.5} /> {/* Empty grid item to shift Phone field */}
  <Grid item xs={7.5}> {/* Phone field */}
    <TextField
      required
      onChange={(e) => handleUserInput("phone", e.target.value)}
      name="phone"
      autoComplete="off"
      type="number"
    //   placeholder="Phone*"
    label="Phone"
      fullWidth
      onBlur={() => {
        if (formError.phone) {
          message.error(formError.phone);
        }
      }}
      onInput={(e) => {
        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
      }}
      variant="outlined"
      size="small"

      disabled={isPhoneVerifyed}
    />
  </Grid>
  <Grid item xs={4}> {/* Verify button */}
    {isPhoneVerifyed ? (
      <Button
        fullWidth
        variant="outlined"
        style={{
          backgroundColor: isPhoneVerifyed && "green",
          color: isPhoneVerifyed && "#fff",
        }}
      >
        Verified
      </Button>
    ) : (
      <Button
        disabled={formError?.phone || isPhoneVerifyed}
        fullWidth
        variant="outlined"
        color="primary"
        onClick={() => {
          verifyPhone();
        }}
      >
        Verify
      </Button>
    )}
  </Grid>
</Grid>

<Modal
  open={modalForPhoneOtp}
  onClose={setModalForPhoneOtp}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Grid container justifyContent="center" alignItems="center">
    <Grid item>
      <PhoneOtp
        verifyPhone={verifyPhone}
        openOtpHandler={modalForPhoneOtp}
        setOpenOtpHandler={setModalForPhoneOtp}
        verifyMode="Phone Number"
        otpField={phoneOtpField}
        setOtpField={setPhoneOtpField}
        phoneToken={phoneToken}
        setModalForPhoneOtp={setModalForPhoneOtp}
        setIsPhoneVerifyed={setIsPhoneVerifyed}
        phoneLabel={userRegistration?.phone}
        userRegistration={userRegistration}
      />
    </Grid>
  </Grid>
</Modal>


        {/* <Grid item xs={12}>
          <TextField required label="Company Type" fullWidth select variant="outlined" size="small">
            <MenuItem value="Type 1">Type 1</MenuItem>
            <MenuItem value="Type 2">Type 2</MenuItem>
            <MenuItem value="Type 3">Type 3</MenuItem>
          </TextField>
        </Grid> */}

<Grid item xs={12}> {/* Company Type field */}
  <FormControl fullWidth required>
    <Select 
      variant="outlined" 
      size="small"
      labelId="demo-simple-select-autowidth-label"
      id="demo-simple-select-autowidth"
      value={selectedCompanyType}
      onChange={(e) => {
        const selectedValue = e.target.value;
        setSelectedCompanyType(selectedValue);
        let data = companyTypeList?.filter((data) => {
          return data.name === selectedValue;
        });
        handleUserInput("companyType", data[0].id);
      }}
      displayEmpty
      renderValue={(selected) => {
        if (!selected) return "Type of company*";
        else return selected;
      }}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem disabled value="">
        Type of company*
      </MenuItem>
      {companyTypeList?.map((data) => (
        <MenuItem key={data?.id} value={data?.name}>
          {data?.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>


        <Grid item xs={12}> {/* Company Name field */}
          {/* <TextField required label="Company Name" fullWidth variant="outlined" size="small" /> */}
          <TextField
                      required
                      name="registered_org"
                      autoComplete="off"
                      label="Registered Company Name"
                      fullWidth
                      variant="outlined" 
                      size="small"
                      onBlur={() => {
                        if (formError.organization) {
                          message.error(formError.organization);
                        }
                      }}
                      onChange={(e) =>
                        handleUserInput("organization", e.target.value)
                      }
                      displayEmpty
                     
                      renderValue={(selected) => {
                        if (!selected) return "";
                        else return selected;
                      }}
                    />
        </Grid>
        <Grid item xs={12}> {/* Role field */}
          {/* <TextField required label="Role" fullWidth variant="outlined" size="small" /> */}
          <TextField
                     required
                      name="role"
                      // label="Your Role*"
                      label="Role"
                      autoComplete="off"
                      fullWidth
                      variant="outlined" 
                      size="small"
                      onBlur={() => {
                        if (formError.role) {
                          message.error(formError.role);
                        }
                      }}
                      onChange={(e) => handleUserInput("role", e.target.value)}
                      
                    />
        </Grid>
        <Grid item xs={12}> {/* Website field */}
          {/* <TextField label="Website (optional)" fullWidth variant="outlined" size="small" /> */}
          <TextField
                      name="website"
                      autoComplete="off"
                      label="Website (optional)"
                      fullWidth
                      variant="outlined" 
                      size="small"
                      onChange={(e) =>
                        handleUserInput("website", e.target.value)
                      }
                    
                    />
        </Grid>
        {/* <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControlLabel
            control={<Checkbox />}
            label={(
              <>
                <Typography variant="body2">
                  I agree to these <a href="#" style={{ color: '#1976d2', textDecoration: 'none' }}>Terms and Conditions</a>  <span style={{ fontSize: '1rem', marginLeft: '4px' }}>*</span>
                </Typography>
              </>
            )}
          />
        </Grid> */}

<Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
  <FormControlLabel
    control={
      <Checkbox
        id="vehicle2"
        name="term"
        checked={term}
        onClick={(e) => setTerm(!term)}
      />
    }
    label={
      <Typography variant="body2">
        I agree to these{' '}
        <a
          href="https://jobizo.com/terms-and-conditions/"
          style={{ color: '#2D5FA1', textDecoration: 'none' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>
        <span style={{ fontSize: '1rem', marginLeft: '4px' }}>*</span>
      </Typography>
    }
  />
</Grid>

        {/* <Grid item xs={12}>
          <Button
            type="submit"
            onClick={handleRegistrationInput}
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: '#1976d2',
              color: '#fff',
              padding: '8px',
              borderRadius: '4px',
              textTransform: 'none',
              fontWeight: 'bold',
              '&:hover': {
                backgroundColor: '#1565c0',
              },
            }}
          >
            Register with hospital
          </Button>
        </Grid> */}
        <Grid item xs={12}> {/* Submit button */}
  <Button
  type="submit"
  method="POST"
    // onClick={handleRegistrationInput}
    onClick={(e) => {
      // e.preventDefault()
      handleRegistrationInput()
    }
    }
    variant="contained"
    fullWidth
    sx={{
      backgroundColor: Object.keys(formError).length !== 0 ? "#f8a0a0" : "#d62511",
      color: '#fff',
      borderRadius: '25px',
      fontWeight: 'bold',
      justifyContent: 'center',
      height: '28px',
      '&:hover': {
        backgroundColor: Object.keys(formError).length !== 0 ? "#f8a0a0" : "#b51b0e",
      },
    }}
    disabled={Object.keys(formError).length !== 0}
  >
   Register as Employer
  </Button>
</Grid>

      </Grid>
    </Box>
  );
}

export default RegistrationForm;
