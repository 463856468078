import React from "react";
import "./RangeChart.scss";

import ReactApexChart from "react-apexcharts";
import graphqlRequest from "../../../requestMethod";
import eventHelpers from "../../../Helpers/Event";

function RangeChart({ dashboardData, categoriesData, chartTitle }) {
  // console.log("1--->", dashboardData);
  // console.log("2--->", categoriesData)
  // console.log("3--->", chartTitle)

  // let data = {
  //   series: [
  //     {
  //       // name: "Part-time jobs",
  //       name: 'Month',
  //       data: categoriesData.map((category, index) => ({
  //         x: dashboardData?.locumJobsTotal[index],
  //         y: index,
  //       })),
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       height: 350,
  //       type: "bar",
  //       zoom: {
  //         enabled: false,
  //       },
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     plotOptions: {
  //       bar: {
  //         horizontal: false,
  //       },
  //     },
  //     stroke: {
  //       curve: "smooth",
  //     },
  //     title: {
  //       text: chartTitle,
  //       align: "left",
  //     },
  //     legend: {
  //       show: true,
  //       inverseOrder: true,
  //     },
  //     grid: {
  //       row: {
  //         colors: ["#f3f3f3", "transparent"],
  //         opacity: 0.5,
  //       },
  //     },
  //     xaxis: {
  //       type: "integer",
  //       title: {
  //         text: "Locum Total Jobs",
  //       },
  //     },
  //     yaxis: {
  //       categories: categoriesData,
  //       title: {
  //         text: "Months",
  //       },
  //       // labels: {
  //       //   rotate: -45,
  //       // },
  //     },
  //   },
  // };

  // console.log("1>>>>", categoriesData)

  const currentMonthIndex = new Date().getMonth();
  // Reorder categoriesData to start with the current month
  const reorderedCategories = [...categoriesData.slice(currentMonthIndex), ...categoriesData.slice(0, currentMonthIndex)];
// console.log("2--->", reorderedCategories)
 

  let data = {
    series: [
      {
        name: "Locum jobs",
        data: dashboardData?.locumJobsTotal,
        // data: dashboardData?.last12MonthJobs,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        curve: "smooth",
      },
      // stroke: {
      //   curve: "straight",
      // },
      title: {
        text: chartTitle,
        align: "left",
      },
      legend: {
        show: true,
        // customLegendItems: ['Team B', 'Team A'],
        inverseOrder: true,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: reorderedCategories,
        labels: {
          rotate: -45,
        },
      },
    },
  };

  // const totalJobs =
  //   dashboardData?.totallocumJobs + dashboardData?.totalPermanentJobs;
  const totalJobs = dashboardData?.last12MonthJobs;
  // const totalJobs = dashboardData?.locumJobs;

  const fetchUserId = async () => {
    let data = localStorage.getItem("auth");
    let token = JSON.parse(data)?.accessToken;
    try {
      const query = `
      query {
        withAuth(token: "${token}"){
         userDetails{
          id,
          name {
            salutation
            first
            middle
            last
            full
          },
          emails {
            isPrimary
            isVerified
            address
          },
          phoneNumbers{
            number
          }
        }
        }
      }
        `;
      const response = await graphqlRequest(query);
      // console.log("175-->",response)
      return response;
    } catch (error) {
      console.error("Error sending GraphQL request:", error);
    }
  };

  const trackDashTotalJob = async () => {
    const user = await fetchUserId();
    // console.log("user---->", user);
    const userId = user.data.withAuth.userDetails.id;
    const userName = user.data.withAuth.userDetails.name.full;
    const userEmail = user.data.withAuth.userDetails.emails[0].address;
    const userPhone = user.data.withAuth.userDetails.phoneNumbers[0].number;

    if (window.Moengage) {
      //set unique id
      window.Moengage.add_unique_user_id(userId);
      //set user attributes
      window.Moengage.add_user_name(userName);
      window.Moengage.add_email(userEmail);
      window.Moengage.add_mobile(userPhone);

      //set event data
      window.Moengage.track_event(
        eventHelpers.DASH_BOARD_HVR
        // {
        // "ID": userId,
        // "name": userName,
        // "Email": userEmail,
        // "date": Date.now(),
        //}
      );
      // window.Moengage.destroy_session();
    }
  };

  return (
    <div className="range-chart-container">
      <div className="range-chart-wrapper">
        <div className="rcw-top">
          <div className="rcw-top-left">
            <h3>Total Jobs Posted</h3>
            <div>
              <h3>{totalJobs ? totalJobs : ""}</h3>
              {/* <div>
                <img src={toparrow} alt="top-arrow" />
                <p>5%</p>
              </div> */}
              {/* <p>Than last year</p> */}
            </div>
          </div>
          <div className="rcw-top-right"></div>
        </div>
        <div className="rcw-bottom">
          <ReactApexChart
            options={data.options}
            series={data.series}
            // type="line"
            type="bar"
            height={"100%"}
            onMouseOver={trackDashTotalJob}
          />
        </div>
      </div>
    </div>
  );
}

export default RangeChart;
