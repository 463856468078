import React from "react";
import "./jobListingCard.scss";

import SearchIcon from "../../asset/footer/search-job.svg";
import Manipal from "../../asset/footer/manipal-icon.svg";
import RightArrowBlue from "../../asset/footer/arrow-right_blue.svg";

const index = () => {
  return (
    <div className="job_lisiting_card">
      <div className="job_listing_first_block">
        <div className="job_listing_first_block_search_wrappper">
          <div className="search_icon">
            <img src={SearchIcon} alt="magnifying_glass" className="img" />
          </div>
          <p>Actively hiring</p>
        </div>
        <div className="job_listing_first_block_title_wrappper">
          <p className="job_heading">Consultant Cardiologist</p>
          <p className="job_pay">₹ 800 - 2000/-</p>
        </div>
      </div>
      <div className="job_listing_second_block">
        <div className="hospital_icon">
          <img src={Manipal} alt="Manipal Hospital" className="img" />
        </div>
        <div className="hospital_address">
          <p className="hospital_name">Manipal Hospitals</p>
          <p className="hospital_location">Bangalore, (Onsite)</p>
        </div>
      </div>
      <div className="divider"></div>
      <div className="job_listing_third_block">
        <div className="job_listing_deadline">
          <div className="job_listing_details">
            <div className="job_listing_detail_heading">Start Date</div>
            <div className="job_listing_detail">20th Jan 2024</div>
          </div>
          <div className="job_listing_details">
            <div className="job_listing_detail_heading">End Date</div>
            <div className="job_listing_detail">23rd Jan 2024</div>
          </div>
          <div className="job_listing_details">
            <div className="job_listing_detail_heading">Duration</div>
            <div className="job_listing_detail">7 days</div>
          </div>
        </div>
        <div className="job_listing_employer">
          <div className="job_listing_employer_details">
            <p className="job_listing_employer_details_heading">Posted by</p>
            <p className="detail">Ifanglobal</p>
          </div>
          <div className="job_listing_employer_view_details">
            <p>View Details</p>
            <div className="right_arrow">
              <img src={RightArrowBlue} alt="next_arrow" className="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
