import publicRequest from "../requestMethod";
import {
  loginFailed,
  loginStart,
  loginSuccess,
  signUpStart,
  signUpSuccess,
} from "./userReducer";
import graphqlRequest from "../requestMethod";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  // console.log("user>>", user);
  const { email, password } = user;

  const query = `
    query{
         loginV1(emailOrPhone:"${email}",password:"${password}",isWeb:true){
            accessToken, refreshToken, isAdmin, verifyToken
         }
        }
  `;
  try {
    const response = await graphqlRequest(query);
    // console.log("response>>apicall",response);
    if (response?.data?.loginV1) {
      localStorage.setItem("auth", JSON.stringify(response?.data?.loginV1));
    }
    return response;
  } catch (error) {
    alert("Email or Password is incorrect");
  }
};

export const loginWithGmail = async (dispatch, token) => {
  dispatch(loginStart());
  const googleToken = token;
  // console.log("TOKEN", googleToken);

  const query = `
  query {
    loginWithGmail(socialToken:"${googleToken}",isWeb:true){
      accessToken,
      name,
      email,
      pic,
      email_verified
    }
  }
  `;
  try {
    const response = await graphqlRequest(query);
    console.log(response);
    if (response?.data) {
      console.log(
        "LOGIN WITH GMAIL",
        response?.data?.loginWithGmail?.accessToken
      );

      if (!response?.data?.loginWithGmail?.accessToken) {
        console.log("REDIRECT TO SIGNUP");
        dispatch(signUpStart(response));
        return response;
      } else {
        dispatch(loginSuccess(response));
        localStorage.setItem(
          "auth",
          JSON.stringify(response?.data?.loginWithGmail)
        );
      }
    }
    return response;
  } catch (error) {
    alert("Email or Password is incorrect");
  }
};

export const clearState = async (dispatch) => {
  dispatch(signUpSuccess());
  return "Successfully Signed Up";
};


