import React, { useState } from "react";
import "./Home.css";
import logo from "../../asset/navbar/logo.svg";
import RegistrationForm from "./RegistrationForm";
import LoginForm from "./LoginForm";
import africanDoctor from "../../asset/navbar/african_doctor_images.svg";
import desktopIcon from "../../asset/navbar/desktop_image.svg";
import botIcon from "../../asset/navbar/bot_image.svg";
import { Menu, MenuItem, IconButton, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Landing from "./Landing/Landing"
const Home = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [hideBot, setHideBot] = useState(false); // State to hide bot icon
  const [hideDesktop, setHideDesktop] = useState(false); // State to hide desktop icon
  const [hideRegisterButton, setHideRegisterButton] = useState(false); 

  const toggleSignUpForm = () => {
    setShowSignUpForm(!showSignUpForm);
    setHideBot(true); // Hide bot icon
    setHideDesktop(true); // Hide desktop icon
    setHideRegisterButton(true); // Hide register button
  };

  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-logo">
          <a href="/">
            <img src={logo} alt="Jobizo Logo" />
          </a>
        </div>
        <div className="container">
          <div className="navbar-links">
            <a href="https://jobizo.com/professionals/">Why Jobizo</a>
            <a href="https://jobizo.com/employer/">Hire Top Professionals</a>
            {/* <div className="dropdown">
              <Typography
                variant="body1"
                component="span"
                className="dropdown-label"
                style={{
                  // fontFamily: 'Noto Sans',
                  fontWeight: 540,
                  // fontSize: '18px',
                  // lineHeight: '24.52px',
                  // textAlign: 'right'
                }}
              >
                Hire Top Professionals
              </Typography>
              <IconButton
                aria-controls={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component="a" href="/">
                  Option 1
                </MenuItem>
                <MenuItem onClick={handleClose} component="a" href="/">
                  Option 2
                </MenuItem>
                <MenuItem onClick={handleClose} component="a" href="/">
                  Option 3
                </MenuItem>
              </Menu>
            </div> */}
            <a href="https://jobizo.com/about/">Contact Us</a>
          </div>
        </div>
        <div className="navbar-buttons">
          <button className="login-btn" onClick={toggleLoginForm}>Log In</button>
          {!hideRegisterButton && (
            <button className="register-btn" onClick={toggleSignUpForm}>
              Register as Employer
            </button>
          )}
        </div>
      </nav>

      {showSignUpForm && <RegistrationForm />}
      {showLoginForm && <LoginForm />}
      <div className="hero-section">
        <div className="hero-content">
          <h1>The Future of Healthcare Hiring</h1>
          <p>
            {/* Whether you are an Employer looking for the right Healthcare
            Professionals */}
            Are you an employer looking for right healthcare professional
          </p>
          {/* {!hideRegisterButton && (
            <button className="register-btn" onClick={toggleSignUpForm}>
             Register as Employer
            </button>
          )} */}
        </div>
        <div className="hero-image">
          {/* <img src={africanDoctor} alt="Character" /> */}
          {/* {!hideBot && (
            <div className="bot-image">
              <img src={botIcon} alt="bot" />
            </div>
          )} */}
         {!hideDesktop && (
            <div className="desktop-image">
              <img src={desktopIcon} alt="desktop" />
            </div>
          )}
        </div>

        
      </div>
      <Landing/>
    </>
  );
};

export default Home;
