import React from "react";
import FooterLogo from "../../../asset/footer-logo.svg";
import TwitterLogo from "../../../asset/social-media/Twitter.svg";
import FbLogo from "../../../asset/social-media/Facebook.svg";
import LinkedInLogo from "../../../asset/social-media/Linkedin.svg";
import RightArrow from "../../../asset/footer/Right-Arrow.svg";
import IdCard from "../../../asset/footer/jobs_id_card.svg";
import Clock from "../../../asset/footer/clock.svg";
import Analytics from "../../../asset/footer/analytics.svg";
import Handshake from "../../../asset/footer/people_handshake.svg";
import GirdDashboard from "../../../asset/footer/dashboard-girl.svg";
import Vacancies from "../../../asset/footer/girl-shout-job.svg";
import Hiring from "../../../asset/footer/guy_giving_presentation.svg";
import Onboarding from "../../../asset/footer/doctor-discussion.svg";
import SoltuionCard from "../../../components/Solution_Card";
import JobListingCard from "../../../components/JobListingCard";
import HowJobizoWorksCard from "../../../components/HowJobizoWorkCard";

import "./Landing.scss";

const Landing = () => {
  const handleOnChange = {};
  return (
    <>
      <div className="landing">
        <section className="solutions">
          <p className="heading">Healthcare Workforce Excellence Solutions</p>
          <div className="light_blue_bg_div">
            <div className="card_section">
              <SoltuionCard
                title="10,000+"
                redText="Jobs Fulfilled"
                alt=""
                icon={IdCard}
                desc="We have successfully matched thousands of healthcare professionals with the right jobs."
                knowMore="#"
              />
              <SoltuionCard
                title="65,000+"
                redText="Database"
                icon={Analytics}
                alt="dashboard"
                desc="Access an extensive database of over 65,000 qualified healthcare professionals ready to be hired."
                knowMore="#"
              />
              <SoltuionCard
                title="400+"
                redText="Employers"
                icon={Handshake}
                alt="handshake"
                desc="Trusted by more than 400 clients for their healthcare hiring needs, ensuring quality and reliability."
                knowMore="#"
              />
              <SoltuionCard
                title="5,00,000"
                redText="Care Hours"
                icon={Clock}
                alt="clock"
                desc=" Providing dedicated and professional healthcare services, totaling over 500,000+ hours of patient care."
                knowMore="#"
              />
            </div>
          </div>
        </section> 
        <section className="jobs_listing">
          <p className="heading">Latest Exclusive Jobs on Jobizo</p>
          <div className="job_listing_div">
          <JobListingCard/>
          <JobListingCard/>
          <JobListingCard/>
          <JobListingCard/>
          </div>
        </section>
        <section className="how_jobizo_works">
          <p className="how_jobizo_works_heading">How Jobizo Works for Employers ?</p>
          <p className="how_jobizo_works_desc">Whether you are an Employer looking for the right Healthcare Professionals or you are a Healthcare Professional looking for better & flexible jobs with work life balance. We have something for everyone!</p>
        <div className="how_jobizo_works_light_blue_bg_div">
          <div className="card_section">
          
          <HowJobizoWorksCard
          image={GirdDashboard}
          heading="Dashboard Login"
          desc="Log in to employer dashboard"
          alt="dashboard"
          />
          <HowJobizoWorksCard
          image={Vacancies}
          heading="Vacancies"
          desc="Find Vacancies for Professionals"
          alt="shoutout"
          />
          <HowJobizoWorksCard
          image={Hiring}
          heading="Hiring"
          desc="Find Professionals as per your need"
          alt="hiring_process"
          />
          <HowJobizoWorksCard
          image={Onboarding}
          heading="Onboarding"
          desc="Onboard Healthcare Professionals"
          alt="doctor_getting_onboarded"
          />
          </div>
        </div>
        </section>
        <footer className="footer">
          <div className="footer_container">
            <div className="footer_contact_us">
              <div className="first-block">
                <p className="p1 font-700">Need more information?</p>
                <p className="font-400">
                  Write your concern to us and our specialist will get back to
                  you.
                </p>
              </div>
              <div className="second-block">
                <button className="blue_button"> Connect Us</button>
              </div>
            </div>
            <div className="footer_wrapper">
              <div className="footer_links_section">
                {/* <div className="footer_link_block">
                  <p className="footer-link-heading font-500"> Product</p>
                  <div className="footer_links">
                    <a>Employee database</a>
                    <a>Payroll</a>
                    <a>Absences</a>
                    <a>Time tracking</a>
                    <a>Shift planner</a>
                    <a>Recruiting</a>
                  </div>
                </div> */}
                <div className="footer_link_block_2">
                  <p className="footer-link-heading font-500"> Information</p>
                  <div className="footer_links">
                    <a href="https://jobizo.com/about/"> FAQ</a>
                    <a>Blog</a>
                    <a>Support</a>
                  </div>
                </div>
                <div className="footer_link_block">
                  <p className="footer-link-heading font-500"> Company</p>
                  <div className="footer_links">
                    <a href="https://jobizo.com/about/">About us</a>
                    <a>Careers</a>
                    <a href="https://jobizo.com/about/">Contact us</a>
                  </div>
                </div>
                {/* <div className="footer_link_block_1">
                  <p className="footer-link-heading font-500">Register Now</p>

                  <div className="input_form">
                    <input
                      type="email"
                      name="email"
                      className="subscribe_field"
                      //   value={email}
                      placeholder="Email address"
                      onChange={handleOnChange}
                    />

                    <button className="submit_button">
                      <div className="arrow_icon">
                        <img className="img" src={RightArrow} alt="arrow" />
                      </div>
                    </button>
                  </div>

                  <p className="p6 footer_desc">
                    Hello, we are Jobizo. Our goal is to translate the positive
                    effects from revolutionizing how companies engage with their
                    clients & their team.
                  </p>
                </div> */}
              </div>
              <div className="divider"></div>

              <div className="footer_last_section">
                <div className="footer_logo">
                  <img className="img" src={FooterLogo}></img>
                </div>

                <div className="terms_block">
                  <a href="https://jobizo.com/terms-and-conditions/" className="p4 font-400">
                    Terms
                  </a>
                  <a href="https://jobizo.com/platform-user-engagement/" className="p4 font-400">
                    Privacy
                  </a>
                  <a href="#" className="p4 font-400">
                    Cookies
                  </a>
                </div>

                <div className="social_media_block">
                  <a href="#" className="social_link">
                    <img className="img" src={LinkedInLogo}></img>
                  </a>
                  <a href="#" className="social_link">
                    <img className="img" src={FbLogo}></img>
                  </a>
                  <a href="#" className="social_link">
                    <img className="img" src={TwitterLogo}></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Landing;
